import React from 'react';

import Property from './Property';

export interface FocusedPropertyContextType {
  focusedProperty: Property | null;
  updateFocus: (prop: Property | null) => void;
}

export interface FocusedPropertyContextProps {
  focusedPropertyContext: FocusedPropertyContextType;
}

export const FocusedPropertyContext = React.createContext<
  FocusedPropertyContextType
>({
  focusedProperty: null,
  updateFocus: null!,
});

export const withFocusedPropertyContext = <P extends object>(
  Component: React.ComponentType<P & FocusedPropertyContextProps>,
): React.ComponentType<P> => (props: P) => (
  <FocusedPropertyContext.Consumer>
    {(focusedPropertyContext: FocusedPropertyContextType) => (
      <Component {...props} focusedPropertyContext={focusedPropertyContext} />
    )}
  </FocusedPropertyContext.Consumer>
);

export default FocusedPropertyContext;
