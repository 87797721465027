import React from 'react';
import {Box, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Iframe from 'react-iframe';

import PropertyUpdates from './PropertyUpdates';

import Property from '../Property';

import './FocusedPropertyView.css';

interface FocusedPropertyViewProps {
  property: Property;
  removeFocus: () => void;
}

export default function(props: FocusedPropertyViewProps) {
  return (
    <Box className="focused-property-view">
      <IconButton
        onClick={props.removeFocus}
        className="focused-property-close-button">
        <CloseIcon />
      </IconButton>
      {props.property.updates.length > 1 && (
        <PropertyUpdates updates={props.property.updates} />
      )}
      <Iframe
        url={props.property.property_url}
        className="property-page"
        width="100%"
        height="100%"
      />
    </Box>
  );
}
