import React from 'react';

import {Map, TileLayer, Marker} from 'react-leaflet';

import {withAPIContext, APIContextProps} from '../Api';

import {withUserContext, UserContextProps} from '../UserContext';
import {
  withFocusedPropertyContext,
  FocusedPropertyContextProps,
} from '../FocusedPropertyContext';

import Property from '../Property';

import './MapView.css';

type MapViewProps = UserContextProps &
  APIContextProps &
  FocusedPropertyContextProps;

interface MapViewState {
  properties: Property[];
  bounds?: any;
}

class MapViewBase extends React.Component<MapViewProps, MapViewState> {
  private mapRef: any;

  constructor(props: MapViewProps) {
    super(props);
    this.state = {
      properties: [],
      bounds: null,
    };

    this.handleViewportChanged = this.handleViewportChanged.bind(this);
    this.fetchProperties = this.fetchProperties.bind(this);
  }

  componentDidMount() {
    this.fetchProperties();
  }

  handleViewportChanged() {
    this.setState({bounds: this.mapRef.contextValue.map.getBounds()});
    console.log('Fetching points from ' + JSON.stringify(this.state.bounds));
    this.fetchProperties();
  }

  fetchProperties() {
    const headers = {
      Authorization: `Bearer ${this.props.userContext.user!.accessToken}`,
    };
    const request: any = {headers: headers};
    if (this.state.bounds !== null) {
      const b = this.state.bounds;
      request.params = {
        NELat: b.getNorth(),
        NELon: b.getEast(),
        SWLat: b.getSouth(),
        SWLon: b.getWest(),
      };
    }
    this.props.apiClient.client
      .get('properties', request)
      .then(res => {
        this.setState({
          properties: res.data.properties,
        });
        console.log(`Got ${this.state.properties.length} properties`);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const kingsCross: [number, number] = [51.5320013, -0.1233234];
    const zoom = 13;
    return (
      <Map
        ref={ref => {
          this.mapRef = ref;
        }}
        center={kingsCross}
        zoom={zoom}
        onViewportChanged={this.handleViewportChanged}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {this.state.properties.map((prop: Property) => (
          <Marker
            key={`property-${prop.id}`}
            position={[prop.location.lat, prop.location.lon]}
            onClick={(_: any) => {
              this.props.focusedPropertyContext.updateFocus(prop);
            }}
          />
        ))}
      </Map>
    );
  }
}

export default withFocusedPropertyContext(
  withAPIContext(withUserContext(MapViewBase)),
);
