import React from 'react';

import {RouteComponentProps} from 'react-router-dom';

import {withUserContext, UserContextProps} from '../UserContext';

import {Formik, Form, Field, ErrorMessage} from 'formik';

type LoginProps = UserContextProps & RouteComponentProps;

class LoginBase extends React.Component<LoginProps> {
  render() {
    return (
      <div>
        <div>Please Log In</div>
        <Formik
          initialValues={{email: '', password: ''}}
          validate={values => {
            const errors: {
              email?: string;
              password?: string;
            } = {};
            if (!values.email) {
              errors.email = 'Required';
            }

            if (!values.password) {
              errors.password = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, {setSubmitting}) => {
            this.props.userContext
              .login(values.email, values.password)
              .then(() => {
                setSubmitting(false);
                this.props.history.push('/');
              });
          }}>
          {({isSubmitting}) => (
            <Form>
              <label htmlFor="email">Email: </label>
              <Field type="email" name="email" />
              <ErrorMessage name="email" component="div" />
              <label htmlFor="password">Password: </label>
              <Field type="password" name="password" />
              <ErrorMessage name="password" component="div" />
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withUserContext(LoginBase);
