import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

import {withUserContext, UserContextProps} from '../UserContext';

import {LOGIN} from '../routes';

import './Navbar.css';

type NavbarProps = UserContextProps;

export class NavbarBase extends React.Component<NavbarProps> {
  render() {
    return (
      <AppBar className="navbar" position="static">
        <Toolbar>
          <Typography variant="h6" className="title">
            Property Insight
          </Typography>
          <div className="user-section">
            {this.props.userContext.user ? (
              <Button onClick={this.props.userContext.logout} color="inherit">
                Logout
              </Button>
            ) : (
              <Button to={LOGIN} component={Link} color="inherit">
                Login
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withUserContext(NavbarBase);
