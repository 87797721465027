import React from 'react';

import Box from '@material-ui/core/Box';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import Navbar from './components/Navbar';
import Main from './components/Main';
import Login from './components/Login';
import User from './User';
import UserContext, {UserContextType} from './UserContext';
import APIClient, {APIContext} from './Api';
import LocalStorage from './util';

import * as ROUTES from './routes';

import './App.css';

const USER_KEY = 'user';

interface AppProps {}

interface AppState {
  user: User | null;
}

export default class App extends React.Component<AppProps, AppState> {
  private storage: LocalStorage;
  private apiClient: APIClient;

  constructor(props: AppProps) {
    super(props);
    this.storage = new LocalStorage();
    this.apiClient = new APIClient({
      getUser: () => this.state.user,
      updateUser: this.updateUser.bind(this),
    });

    this.state = {
      user: this.loadUser(),
    };

    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
  }

  updateUser(user: User) {
    this.setState({
      user: user,
    });
    this.saveUser(user);
  }

  saveUser(user: User) {
    this.storage.store(USER_KEY, user);
  }
  loadUser(): User | null {
    const result = this.storage.load(USER_KEY);
    if (result == null) {
      console.log('No user to load.');
    } else {
      console.log(`Loaded user ${result.email}`);
    }
    return result;
  }
  removeUser() {
    this.storage.remove(USER_KEY);
  }

  login(email: string, password: string) {
    const c = this;
    return this.apiClient.client
      .post('login', {
        email: email,
        password: password,
      })
      .then(res => {
        const user = {
          email: res.data.email,
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
        c.updateUser(user);
      })
      .catch(err => {
        console.log(err);
      });
  }

  logout() {
    this.removeUser();
    this.setState(current => ({user: null} as AppState));
  }

  render() {
    const userContext: UserContextType = {
      user: this.state.user,
      login: this.login,
      logout: this.logout,
    };

    return (
      <Box width="100%" height="100%" className="app">
        <APIContext.Provider value={this.apiClient}>
          <UserContext.Provider value={userContext}>
            <Router>
              <Navbar userContext={userContext} />
              <Box className="main-content">
                <Route exact path={ROUTES.LANDING} component={Main} />
                <Route path={ROUTES.LOGIN} component={Login} />
              </Box>
            </Router>
          </UserContext.Provider>
        </APIContext.Provider>
      </Box>
    );
  }
}
