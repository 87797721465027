import React from 'react';
import axios, {AxiosInstance} from 'axios';

import User from './User';

const AUTH_ENDPOINTS = ['login', 'refresh'];

export interface UserUpdateContext {
  getUser(): User | null;
  updateUser(user: User): void;
}

class APIClient {
  client: AxiosInstance;
  userUpdateContext: UserUpdateContext;

  constructor(userUpdateContext: UserUpdateContext) {
    this.client = axios.create({
      baseURL: 'https://api.insight.nkartashov.com',
      timeout: 1000 * 100,
    });
    this.userUpdateContext = userUpdateContext;
    this.client.interceptors.response.use(
      response => response,
      error => {
        console.log(error);
        const user = userUpdateContext.getUser();
        if (
          error.response.status === 401 &&
          !AUTH_ENDPOINTS.includes(error.response.config.url) &&
          !error.config.__isRetryRequest &&
          user !== null
        ) {
          // Refresh token if we can.
          return this.client
            .post('refresh', null, {
              headers: {
                Authorization: `Bearer ${user.refreshToken}`,
              },
            })
            .then(res => {
              console.log('Successfully refreshed the token.');
              userUpdateContext.updateUser({
                email: res.data.email,
                accessToken: res.data.access_token,
                refreshToken: res.data.refresh_token,
              });
              error.config.headers.Authorization = `Bearer ${res.data.access_token}`;
              error.config.__isRetryRequest = true;
              return this.client.request(error.config);
            });
        }
        return error;
      },
    );
  }
}

export interface APIContextProps {
  apiClient: APIClient;
}

export const APIContext = React.createContext<APIClient>(null!);

export const withAPIContext = <P extends object>(
  Component: React.ComponentType<P & APIContextProps>,
): React.ComponentType<P> => (props: P) => (
  <APIContext.Consumer>
    {apiClient => <Component {...props} apiClient={apiClient} />}
  </APIContext.Consumer>
);

export default APIClient;
