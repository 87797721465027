import React from 'react';
import {Typography, Grid} from '@material-ui/core';

import MapView from './MapView';
import FocusedPropertyView from './FocusedPropertyView';

import Property from '../Property';

import {withUserContext, UserContextProps} from '../UserContext';
import {
  FocusedPropertyContextType,
  FocusedPropertyContext,
} from '../FocusedPropertyContext';

import './Main.css';

type MainProps = UserContextProps;

interface MainState {
  focusedProperty: Property | null;
}

class MainBase extends React.Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);

    this.state = {
      focusedProperty: null,
    };

    this.removeFocus = this.removeFocus.bind(this);
  }

  removeFocus() {
    this.setState({focusedProperty: null});
  }

  render() {
    const focusedPropertyContext: FocusedPropertyContextType = {
      focusedProperty: this.state.focusedProperty,
      updateFocus: (prop: Property | null) => {
        this.setState({focusedProperty: prop});
      },
    };

    return (
      <FocusedPropertyContext.Provider value={focusedPropertyContext}>
        {this.props.userContext.user ? (
          <Grid container className="grid-container">
            <Grid item sm className="map-container">
              <MapView />
            </Grid>
            {this.state.focusedProperty && (
              <Grid item sm>
                <FocusedPropertyView
                  property={this.state.focusedProperty}
                  removeFocus={this.removeFocus}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <div>
            <Typography variant="h5" className="greeting">
              Hello and welcome! Please log in to get the latest property
              insights.
            </Typography>
          </div>
        )}
      </FocusedPropertyContext.Provider>
    );
  }
}

export default withUserContext(MainBase);
