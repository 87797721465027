import React from 'react';
import {Typography, Box} from '@material-ui/core';

import PropertyUpdate from '../PropertyUpdate';
interface PropertyUpdatesProps {
  updates: PropertyUpdate[];
}

export default function(props: PropertyUpdatesProps) {
  return (
    <Box>
      <Typography>Property changes so far:</Typography>
      {props.updates.map((update: PropertyUpdate) => (
        <React.Fragment key={update.id}>
          <Typography>{update.price}</Typography>
        </React.Fragment>
      ))}
    </Box>
  );
}
