class LocalStorage {
  store(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  load(key: string): any {
    const value = localStorage.getItem(key);
    if (value === null) {
      return value;
    }
    return JSON.parse(localStorage.getItem(key) as string);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}

export default LocalStorage;
