import React from 'react';

import User from './User';

export interface UserContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

export interface UserContextProps {
  userContext: UserContextType;
}

export const UserContext = React.createContext<UserContextType>({
  user: null,
  login: null!,
  logout: null!,
});

export const withUserContext = <P extends object>(
  Component: React.ComponentType<P & UserContextProps>,
): React.ComponentType<P> => (props: P) => (
  <UserContext.Consumer>
    {(userContext: UserContextType) => (
      <Component {...props} userContext={userContext} />
    )}
  </UserContext.Consumer>
);

export default UserContext;
